/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_tree .
 *= require_self
 */

$primary: #154178;
$secondary: #1b63a5;
$text-muted: #666f75;
$body-bg: #f1f1f1;

@import "bootstrap/scss/bootstrap.scss";
@import "./tw-utilities.scss";

div,
header,
footer,
section,
address,
nav,
main {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}

.content-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  a:not(.btn):not(.dropdown-item):not(.nav-link) {
    border-bottom: 1px dotted $primary;
    color: $primary;
  }
  a:not(.btn):not(.dropdown-item):hover,
  a:not(.btn):not(.dropdown-item):focus {
    border-bottom: none;
    text-decoration: underline;
    color: $secondary;
  }
  ul.nav-tabs {
    li.nav-item {
      a {
        border-bottom: none;
      }
      h2 {
        font-size: 1.2rem;
      }
    }
  }
  ul.nav-pills {
    li.nav-item {
      font-size: 1.2rem;
      a.active:hover,
      a.active:focus {
        color: #ffffff;
      }
    }
  }
}

.list-inline-item.with-separator:not(:last-child) {
  margin-right: 0;
}

.list-inline-item.with-separator + .list-inline-item.with-separator::before {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  content: "\2022";
}

.btn.hide-after-click[aria-expanded="true"] {
  display: none;
}

.btn.wrap-text {
  white-space: normal;
}

.btn-group.langtest-report-toggle {
  .btn-primary {
    background-color: $white;
    color: $primary;
  }

  .btn-primary:active,
  .btn-primary.active {
    background-color: $primary;
    color: $white;
  }
}

.position-fixed.centered-alert-wrapper {
  z-index: $zindex-tooltip;
  left: 50%;
  .alert {
    left: -50%;
  }
}

nav.langtest-nav {
  ul.navbar-nav {
    @include media-breakpoint-down(sm) {
      padding: 0.5rem 0 0.5rem 0;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid $gray-300;
    }
    li.nav-item {
      a.nav-link {
        padding: 0;
        margin-left: 0;
        margin-right: 2rem;
        border-bottom: 2px solid transparent;
        color: $black;
      }
      a.nav-link:hover {
        @include media-breakpoint-up(md) {
          border-bottom: 2px dotted $primary;
        }
        @include media-breakpoint-down(sm) {
          text-decoration: underline;
        }
        color: $primary;
      }
      a.nav-link.active {
        @include media-breakpoint-up(md) {
          border-bottom: 2px solid $secondary;
        }
        @include media-breakpoint-down(sm) {
          text-decoration: underline;
          font-weight: bold;
        }
        color: $black;
      }
    }
  }
}

form.was-validated {
  .form-control:valid.custom-validated {
    // reset border color on report fields using custom validation, otherwise
    // Bootstrap will add a green border to a field which is not actually valid.
    border-color: $input-border-color;
  }
}

nav.langtest-nav-admin {
  // calculated from rgba($secondary, .15), because WAVE doesn't understand rgba.
  background-color: #d1dce6;
}

.alert h1 {
  font-size: 1.2rem;
  font-weight: 600;
}

legend.styled-as-label {
  font-size: 1rem;
}

.btn-link.accordion-header {
  background-color: #f7f7f7; // To make WAVE and Axe happy
  h3 {
    font-size: 1rem;
    margin: 0;
  }
}
