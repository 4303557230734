// Tailwind Style Utilities

.tw-h-6 {
  height: 1.5rem;
}

.tw-w-6 {
  width: 1.5rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-w-8 {
  width: 2rem;
}

.tw-vh-50 {
  height: 50vh;
}

.tw-min-h-screen {
  min-height: 100vh;
}

.tw-flex {
  display: flex;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-flex-1 {
  flex: 1 1 0%;
}

.tw-text-neutral-500 {
  color: #ccc;
}
